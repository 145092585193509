import Vue from 'vue'
import axios from 'axios'

Vue.use({
    install(Vue) {
        Vue.prototype.$business_management_api = axios.create({
            baseURL: process.env.VUE_APP_URL_BUSINESS_MANAGEMENT_API,
        });
    }
})
