import Vue from "vue"
import Vuex from "vuex"
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'

// import getters from './actions'
// import mutations from './mutations'
// import actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {

        admin: null,

        tokenJWT: null,

        proposalData: null
    },

    // getters,
    // mutations,
    // actions,

    getters: {
        admin(state) {
            return state.admin
        },
        associatedSeller(state) {
            return state.admin ? state.admin.associated_seller : false;
        },
        tokenJWT(state) {
            return state.tokenJWT
        }
    
    },

    mutations: {
        setAdmin(state, dadosAdmin) {
            state.admin = dadosAdmin
            if (dadosAdmin) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${dadosAdmin.token}`
            } else {
                delete axios.defaults.headers.common['Authorization']
            }
        },

        setTokenJWT(state, token) {
            state.tokenJWT  = token
        }
    },

    actions: {
        defineAdmin(context, admin) {
            context.commit('setAdmin', admin)
        },
    
        defineTokenJWT(context, token) {
            context.commit('setTokenJWT', token)
        }
    
    },

    plugins: [ createPersistedState() ]

})
