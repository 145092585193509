import Vue from 'vue'
import axios from 'axios'

Vue.use({
    install(Vue) {
        Vue.prototype.$tallos_admin_api = axios.create({
            baseURL: process.env.VUE_APP_URL_TALLOS_ADMIN_API,
        })
    }
})
