import Vue from 'vue'
import axios from 'axios'

Vue.use({
    install(Vue) {
        Vue.prototype.$tallos_academy_api = axios.create({
            baseURL: process.env.VUE_APP_URL_TALLOS_ACADEMY_API,
            headers: {
                authorization: `Bearer ${process.env.VUE_APP_TOKEN}`
            }
        })
    }
})