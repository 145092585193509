<template>
	<div class="auth-main">
		<div class="auth-logo mb-4 mr-1">
			<img  src="@/assets/images-logo/tallos-logo-name.png" height="58" alt="" class="mb-1">
			<!-- <h1>Tallos Admin</h1> -->
		</div>
		<div class="auth-box">
			<div class="auth-header ">
				<!-- <h2>Tallos Admin </h2>  -->
			</div>

			<div class="auth-content">
				<div class="input-group mb-2">
					<input type="email" v-model="auth.email" name="email" @keyup.enter="login()"
						class="form-control input-email" placeholder="Digite seu Email...">
				</div>
				<div class="input-group">
					<input type="password" v-model="auth.password" name="password" @keyup.enter="login()"
						class="form-control input-pass" placeholder="Digite sua senha...">
				</div>
				<div class="buttons">
					<!-- <div class="check">
						<input type="checkbox" class="icheckbox_square-blue" name="" id="">
						Lembrar Me
					</div> -->
					<button class="btn-login btn pull-right" @click.prevent="login()">
						Login</button>
					<span>suporte@tallos.com.br</span>
				</div>
			</div>

			<div class="auth-footer">
				<span>Tallos Negócios Inteligentes © Copyright 2018</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			auth: {
				email: null,
				password: null
			},
		}
	},

	methods: {
		login() {
			this.$tallos_admin_api.post('auth/signIn', this.auth)
				.then(resp => {
					this.$store.dispatch('defineAdmin', resp.data)
					this.$store.dispatch('defineTokenJWT', resp.data.token)
					this.$tallos_admin_api.defaults.headers.common['Authorization'] = 
						`Bearer ${resp.data.token}`

          switch (this.$store.state.admin.perfil) {
            case 'admin':
              this.$router.push({ path: '/admin/integrations'});
              break;
            case 'seller':
              this.$router.push({ path: '/admin/opportunities'});
              break;
            case 'author':
              this.$router.push({ path: '/admin/blog'});
              break;
            case 'support':
              this.$router.push({ path: '/admin/whatsappApi'});
              break;
            case 'finance':
              this.$router.push({ path: '/admin/plans'});
              break;
            default:
              this.$router.push({ path: '/admin/academy'});
              break;
          }

					resp.data.first = resp.data.first ? true : false
					
					if (resp.data.first) {
						this.$toasted.global.defaultSuccess({msg: `Você é o Administrador.`})
					} else {
						this.$toasted.global.defaultSuccess({msg: `${resp.data.name} logado.`})
					}
					
				})
				.catch(e => {
					this.$toasted.global.defaultError({msg: e.response.data})
				})
		}
	},

	created() {
		this.$store.dispatch('defineAdmin', null)
		this.$store.dispatch('defineTokenJWT', null)
	}
}
</script>
<style scoped>

.auth-main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* border: 1px solid black; */
	width: 100%;
	height: 100vh;
	background: linear-gradient(to right, #000000, #434343);
	overflow: hidden ;
}

.auth-logo {
	margin-bottom: 20px;
}
.auth-logo h1 {
	font-size: 50px;
	color: #2196F3;
}

.auth-box {
	box-shadow: -10px 15px 0px 0px rgba(0, 0, 0, 0.03);
	padding: 15px 30px;
	background-color: #fff;
	max-height: 340px;
	width: 340px;
	border-radius: 13px;
}

.auth-header {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}

.input-email, .input-pass {
	margin-bottom: 10px
}

.buttons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.btn-login {
	margin-top: 20px;
	width: 280px;
	border-radius: 20px;
	background: #009ACC;
	color: #fff;
	font-size: 17px;
	font-weight: 600;
}

.buttons > span {
	color: rgb(102, 102, 102); 
	font-size: 13px; 
	font-weight: bold; 
	margin-top: 15px; 
	padding-bottom: 10px; 
	text-align: center;
}

.auth-footer {
	display: flex;
	justify-content: center;
	padding: 10px 10px;
	margin-top: 5px;
	/* padding-top: 10px; */
	border-top: 1px solid rgb(156, 156, 156, .2);
}

.d-flex-rc {
	display: flex;
	justify-content: center;
	align-items: center;
}

.auth-footer span {
	color: rgb(156, 156, 156);
	font-size: 12px; 
}
</style>