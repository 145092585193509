<template>
  <div class="wrapper">
    <HeaderTop/>

    <MenuLeft/>

    <router-view></router-view>

    <FooterAdmin/>

    <FooterButton/>
  </div>
</template>

<script>

export default {
  components: {
    HeaderTop: () => import('./partials/admin/_header_top'),
    MenuLeft: () => import('./partials/admin/_menu'),
    FooterAdmin: () => import('./partials/admin/_footer'),
    FooterButton: () => import('./partials/admin/_footer_buttton')
  },

  methods: {
    async validateToken() {
      const admin = this.$store.getters.admin
      this.$store.dispatch('defineAdmin', null)

      if (!admin) {
        this.$router.push({name: "auth"});
        return;
      }

      const res = await this.$tallos_admin_api.post(`auth/validateToken`, admin);

      if (res.data) {
        this.$store.dispatch('defineAdmin', admin)
      } else {
        this.$store.dispatch('defineAdmin', null)
        this.$router.push({name: "auth"});
      }
    },
  },

  created() {
    this.validateToken()
  },

}
</script>