
import 'bootstrap/dist/css/bootstrap.min.css'
import 'ionicons/dist/css/ionicons.min.css'

import 'admin-lte/plugins/fontawesome-free/css/all.min.css'
import 'admin-lte/plugins/fontawesome-free/css/fontawesome.min.css'

import 'admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css'
import 'admin-lte/dist/css/adminlte.min.css'

import 'admin-lte/plugins/jquery/jquery'
import 'admin-lte/plugins/jquery/jquery.min.js'
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js'
import 'admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js'
import 'admin-lte/dist/js/adminlte.min.js'
// import 'admin-lte/dist/js/demo.js'

import 'popper.js/dist/umd/popper.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'

