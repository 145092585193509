import Vue from "vue"
import Toasted from "vue-toasted"

Vue.use(Toasted, {
    iconPack: 'fontawesome',
    duration: 1000,
    keepOnHover: true
})

Vue.toasted.register(
    'defaultSuccess',
    payload => !payload.msg ? 'Operação realizada com sucesso!' : payload.msg,
    { type: 'success', icon: 'check' }
)

Vue.toasted.register(
    'defaultError',
    payload => !payload.msg ? 'Ops... Erro inesperado!' : payload.msg,
    { type: 'error', icon: 'times', duration: 2600}
)

Vue.toasted.register(
    'default',
    payload => !payload.msg ? 'Ops... Erro inesperado!' : payload.msg,
    { type: '', icon: '' }
)
