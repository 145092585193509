window.$ = window.jQuery = require("jquery");

import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import router from "./router/router";
import "./config/dependencies";
import "./services/api";
import "./services/academyApi";
import "./services/business-management-api";
import "./services/gupshup-api-key-update";
import "./config/msgs";
import "./config/font-awesome";
// import "chartkick/chart.js"
import VueYoutube from "vue-youtube";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Datepicker from 'vuejs-datepicker';
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.snow.css";
import Clipboard from "v-clipboard";
import VueMoney from "v-money";
import VueMask from "v-mask";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput);
Vue.use(VueMoney, { precision: 4 });
Vue.use(VueQuillEditor);
Vue.use(VueMask);

import "@pencilpix/vue2-clock-picker/dist/vue2-clock-picker.min.css";
const VueClockPickerPlugin = require("@pencilpix/vue2-clock-picker/dist/vue2-clock-picker.plugin.js");
Vue.use(VueClockPickerPlugin);
Vue.use(Clipboard);

Vue.config.productionTip = false;

export const bus = new Vue();

Vue.use(VueYoutube);
Vue.component(Datepicker)

Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
