import Vue from 'vue'
import axios from 'axios'

Vue.use({
    install(Vue) {
        Vue.prototype.$gupshup_api_key_update = axios.create({
            baseURL: process.env.VUE_APP_URL_GUPSHUP_API_KEY_UPDATE,
        });
    }
})
