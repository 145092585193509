import Vue from "vue"
import VueRouter from 'vue-router'
import Auth from '../components/views/layouts/Auth'
import User from '../components/views/layouts/User'
import store from '../store/store'

Vue.use(VueRouter)
const NorthStar = () => import ('../components/views/companies/northstar/index.vue')
const Opportunities = () => import("../components/views/opportunities")
const Contracts = () => import("../components/views/contracts/index.vue")
const Integrations = () => import("../components/views/integrations")
const IntegrationsDetails = () => import("../components/views/integrations/details")
const Companies = () => import("../components/views/companies")
const EditCompany = () => import("../components/views/companies/edit_company")
const InfoCompany = () => import("../components/views/companies/info_company")
const TicketsCompany = () => import("../components/views/companies/tickets_company")
const EmployeesCompany = () => import("../components/views/companies/employees/Employees.vue")
const Users = () => import("../components/views/users")
const AddUser = () => import("../components/views/users/addUser")
const EditUser = () => import("../components/views/users/editUser")
const DeleteUser = () => import("../components/views/users/deleteUser")
const Profile = () => import("../components/views/users/profile")
// const Plans = () => import("../components/views/plans/Index.vue")
// const FinancialRequests = () => import("../components/views/financialRequests/Index.vue")
const Finance = () => import("../components/views/financialRequests/Finance.vue")
const Sugestions = () => import("../components/views/sugestions/Index.vue")
const Apresentation = () => import("../components/views/apresentations/CompaniesApresentation")
// const Blog = () => import("../components/views/blog/Blog")
// const CreatePost = () => import("../components/views/blog/CreatePost")
// const EditPost = () => import("../components/views/blog/EditPost")
// const Leads = () => import("../components/views/leads/Leads")
// const WhatsappApi = () => import("../components/views/whatsappApi/WhatsappAPI")
const Logs = () => import("../components/views/logs/logs.vue")
const Academy = () => import("../components/views/academy/Index.vue")
const Normalizations = () => import("../components/views/normalizations/Index.vue")
const GupshupApiKey = () => import("../components/views/normalizations/GupshupApiKey.vue")
const OverviewCourse = () => import("../components/views/academy/components/Overview.vue")
const CreateCourse = () => import("../components/views/academy/components/CreateCourse.vue")
const EditCourse = () => import("../components/views/academy/components/EditCourse.vue")
const CreateCategory = () => import("../components/views/academy/components/CreateCategory.vue")
const EditCategory = () => import("../components/views/academy/components/EditCategory.vue")
const CommentsCourse = () => import("../components/views/academy/components/Comments.vue")
const AnswerComments = () => import("../components/views/academy/components/AnswerComment.vue")
const EditComments = () => import("../components/views/academy/components/EditComment.vue")
const Kanban = () => import("../components/views/kanban")
const News = () => import("../components/views/news")
const NewsCreate = () => import("../components/views/news/create")
const NewsEdit = () => import("../components/views/news/edit")
const CsNotifications = () => import("../components/views/cs/notifications")
const Nps = () => import("../components/views/nps")

const Webhooks = () => import("../components/views/webhooks/webhooks.vue")

const routes = [
    {
        name: 'auth',
        path: '/',
        component: Auth
    },
    {
        name: 'admin',
        path: '/admin',
        component: User,
        children: [
            {
                name: 'integrations',
                path: 'integrations',
                component: Integrations,
                meta: { requireValidate: false }
            },
            // {   
            //     name: 'blog',
            //     path: 'blog',
            //     component: Blog,
            //     meta: { role: ['admin', 'author'] }
            // },
            // {
            //     name: 'create-post',
            //     path: 'blog/create-post',
            //     component: CreatePost,
            //     meta: { role: ['admin', 'author'] }
            // },
            // {
            //     name: 'edit-post',
            //     path: 'blog/edit-post/:id',
            //     component: EditPost,
            //     meta: { role: ['admin', 'author'] }
            // },
            {
                path: 'opportunities',
                component: Opportunities,
                meta: { requireValidate: true, role: ['admin', 'seller'] }
            },
            {
                name: 'contracts',
                path: 'contracts',
                component: Contracts,
                meta: { requireValidate: true, role: ['admin', 'seller'] }
            },
            {
                name: 'companies',
                path: 'companies',
                component: Companies,
                meta: { requireValidate: true, role: ['admin', 'support', 'finance'] }
            },
            {   name: 'northstar',
                path: 'northstar/:id' ,
                component:  NorthStar,
                meta: { requireValidate: true, role: ['admin', 'support', 'finance']}
            },
            {
                path: 'users',
                component: Users,
                meta: { requireValidate: true, role: ['admin'] }
            },
            {
                path: 'add_admin',
                component: AddUser,
                meta: { requireValidate: true, role: ['admin'] }
            },
            {
                name: 'edit_admin',
                path: 'edit_admin/:id',
                component: EditUser,
                meta: { requireValidate: true, role: ['admin'] },
                props: true
            },
            {
                name: 'delete_admin',
                path: 'delete_admin/:id',
                component: DeleteUser,
                meta: { requireValidate: true, role: ['admin'] },
                props: true
            },
            {
                name: 'profile',
                path: 'profile/:id',
                component: Profile,
                meta: { requireValidate: false },
                props: true
            },
            {
                name: 'details_integrations',
                path: 'details_integrations/:id',
                meta: { requireValidate: false },
                component: IntegrationsDetails,
                props: true
            },
            {
                name: 'edit_company',
                path: 'edit_company/:id/:rd_billing',
                meta: { requireValidate: true, role: ['admin', 'support', 'finance'] },
                component: EditCompany,
                props: true
            },
            {
                name: 'info_company',
                path: 'info_company/:id/:rd_billing',
                meta: { requireValidate: true, role: ['admin', 'support', 'finance'] },
                component: InfoCompany,
                props: true
            },
            {
                name: 'tickets_company',
                path: 'tickets_company/:id',
                meta: { requireValidate: true, role: ['admin', 'support'] },
                component: TicketsCompany,
                props: true
            },
            {
                name: 'company_employees',
                path: 'company_employees/:company_id/:company_name/:rd_billing',
                meta: { requireValidate: true, role: ['admin', 'support', 'finance'] },
                component: EmployeesCompany,
                props: true
            },
            // {
            //     name: 'plans',
            //     path: 'plans/:company_id?',
            //     component: Plans,
            // },
            {
                name: 'apresentation',
                path: 'apresentaion',
                component: Apresentation,
                meta: { requireValidate: false },
            },
            // {
            //     name: 'leads',
            //     path: 'leads',
            //     component: Leads,
            // },
            // {
            //     name: 'whatsappApi',
            //     path: 'whatsappApi',
            //     component: WhatsappApi,
            // },
            {
                name: 'academy',
                path: 'academy',
                component: Academy,
                meta: { requireValidate: true, role: ['admin', 'support', 'seller', 'author', 'finance'] },
            },
            {
                name: 'normalizations',
                path: 'normalizations/contacts',
                component: Normalizations,
                meta: { requireValidate: true, role: ['admin', 'support', 'seller', 'author', 'finance'] },
            },
            {
                name: 'gupshup-api-key',
                path: 'normalizations/gupshup-api-key',
                component: GupshupApiKey,
                meta: { requireValidate: true, role: ['admin', 'support', 'seller', 'author', 'finance'] },
            },
            {
                name: 'create_course',
                path: 'academy/create',
                component: CreateCourse,
                meta: { requireValidate: true, role: ['admin', 'support', 'seller', 'author', 'finance'] },
            },
            {
                name: 'logs',
                path: 'logs',
                component: Logs,
                meta: { requireValidate: true, role: ['admin'] },
            },
            // {
            //     name: 'financialRequests',
            //     path: 'financialRequests/:company_id?',
            //     component: FinancialRequests,
            // },
            // {
            //     name: 'finance',
            //     path: 'finance/:company_id?',
            //     component: Finance,
            //     meta: { role: ['admin', 'finance'] }
            // },
            {
                name: 'kanban',
                path: 'kanban',
                meta: { requireValidate: true, role: ['admin', 'support'] },
                component: Kanban
            },
            {
                name: 'news',
                path: 'news',
                meta: { requireValidate: true, role: ['admin', 'support'] },
                component: News
            },{
                name: 'news/create',
                path: 'news/create',
                meta: { requireValidate: true, role: ['admin', 'support'] },
                component: NewsCreate
            },
            {
                name: 'news_edit',
                path: 'news/edit/:id',
                meta: { requireValidate: true, role: ['admin', 'support'] },
                component: NewsEdit,
                props: true
            },
            {
                name: 'notifications',
                path: 'notifications/pop-up',
                meta: { requireValidate: true, role: ['admin', 'author'] },
                component: CsNotifications
            },
            {
                name: 'nps',
                path: 'notifications/nps',
                meta: { requireValidate: true, role: ['admin', 'author'] },
                component: Nps
            },
            {
                name: 'nps.id',
                path: 'notifications/nps/:id',
                meta: { requireValidate: true, role: ['admin', 'author'] },
                component: () => import('../components/views/nps/components/Dashboard.vue')
            },
            {
                name: 'sugestions',
                path: 'sugestions',
                component: Sugestions,
                meta: { requireValidate: false },
            },
            {
                name: 'edit_course',
                path: 'academy/edit/:id',
                component: EditCourse,
                meta: { requireValidate: true, role: ['admin', 'support', 'seller', 'author', 'finance'] },
            },
            {
                name: 'overview_course',
                path: 'academy/overview/:id',
                component: OverviewCourse,
                meta: { requireValidate: true, role: ['admin', 'support', 'seller', 'author', 'finance'] },
            },
            {
                name: 'create_category_course',
                path: 'academy/category/create/:id',
                component: CreateCategory,
                meta: { requireValidate: true, role: ['admin', 'support', 'seller', 'author', 'finance'] },
            },
            {
                name: 'edit_category_course',
                path: 'academy/category/edit/:id',
                component: EditCategory,
                meta: { requireValidate: true, role: ['admin', 'support', 'seller', 'author', 'finance'] },
            },
            {
                name: 'comments_course',
                path: 'academy/comments/:id',
                component: CommentsCourse,
                meta: { requireValidate: true, role: ['admin', 'support', 'seller', 'author', 'finance'] },
            },
            {
                name: 'answer_comments_course',
                path: 'academy/comments/answer/:id',
                component: AnswerComments,
                meta: { requireValidate: true, role: ['admin', 'support', 'seller', 'author', 'finance'] },
            },
            {
                name: 'edit_comments_course',
                path: 'academy/comments/edit/:id',
                component: EditComments,
                meta: { requireValidate: true, role: ['admin', 'support', 'seller', 'author', 'finance'] },

            },
            {
                name: 'webhooks',
                path: 'webhooks',
                component: Webhooks,
                meta: { requireValidate: true, role: ['admin'] },
            },
            {
                name: 'settings',
                path: 'settings',
                component: () => import('../components/views/settings'),
                meta: { requireValidate: true, role: ['admin'] },
            },
            {
                name: 'trial',
                path: 'trial',
                component: () => import('../components/views/trial'),
                meta: { requireValidate: true, role: ['admin'] },
            },
            {
                name: 'reports',
                path: 'reports',
                component: () => import('../components/views/reports'),
                meta: { requireValidate: true, role: ['admin'] },
            },
        ],
    },
    {
        // will match everything
        path: '*',
        redirect: '/admin/integrations'
    }

]

const route = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, savePosition) {
        if (savePosition) {
            return savePosition
        } else if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes, //or -> routes: routes
    exact: true,
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
});


// Tratar controle ACL
route.beforeEach((to, from, next) => {
    if (to.meta.requireValidate) {
        if (to.meta.role.includes(store.state.admin.perfil)) {
            next()
        } else {
            next({
                path: 'integrations',
                component: Integrations,
                meta: { requireValidate: false }
            })
        }
    } else {
        next()
    }
})

export default route;
